<template>
  <div  class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>

 <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
    <v-card style="margin-top:0px;">
    <v-toolbar
      style="height:75px;"
      color="primary"
      class="pa-5"
      dark
    >
      <v-row>
      <v-col
        cols="4"
        lg="4"
        class="mt-5"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu2 = false"
      header-color="primary"
            :max="max"
            :min="min"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="7" lg="7">
        <v-autocomplete
            v-model="username"
            :items="users"
            dense
            filled
            label="Select User"
            @change="getlogs"
          ></v-autocomplete>
        <!-- <vue-select class="style-chooser" placeholder="Select user" v-model="user_id" :reduce="label => label.code" :options="users" @change="getlogs"></vue-select> -->
      </v-col>
      <v-col cols="1" class="my-4">
            <v-btn small color="default" @click="getlogs()">
              Refresh
            </v-btn>
          </v-col>
    </v-row>
    </v-toolbar>
    <div v-if="isLoading && !sessions.length">
      <v-row>
      <v-col
        cols="12"
        md="12"
      >
      <v-skeleton-loader
          v-bind="attrs"
          type="article, action"
        ></v-skeleton-loader>
      </v-col>
      </v-row>
    </div>
    <div v-else>
          <div v-if="!sessions.length" class="pb-5">
        <v-card
    class="mx-auto"
    max-width="344"
  >
    <v-alert
      outlined
      type="primary"
      prominent
      border="left"
    >
      No login sessions found!
    </v-alert>
  </v-card>
    </div>
    <v-row v-else>
      <v-col cols="3">
        <v-card
    class="mx-auto"
    max-width="300"
    tile
  >
  <v-list shaped >
      <v-subheader>Login Sessions</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item,i) in sessions"
          :key="i"
          @click="selectedSession = item.session_id"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
      </v-col>
      <v-col cols="9">
        <v-timeline dense
      clipped>
          <v-timeline-item
            v-for="(log, i) in logs"
            :key="i"
            color="primary"
            small
            icon-color="grey lighten-2"
          >
            <!-- <v-row justify="space-between">
          <v-col cols="7">
           Module: {{log.module_name}} / Operation: {{log.operation_name}}
          </v-col>
          <v-col
            class="text-left"
            cols="5"
          >
            {{formatTime(log.access_date)}}
          </v-col>
        </v-row> -->
        <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{formatTime(log.access_date)}}</strong>
            </v-col>
            <v-col cols="6">
              <strong title="Module">{{log.module_name}}</strong>
              <div class="text-caption">
                <span title="Operation">{{log.operation_name}}</span>
                <v-icon style="margin-left:5px;margin-right:5px;"> mdi-arrow-right</v-icon>
                <span title="Endpoint">{{log.request_endpoint}}</span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-icon left title="More Info" @click="showMoreInfoModal(log.module_id, log.operation_id, log.user_session_id)">
                  mdi-information-outline
                </v-icon>
            </v-col>
          </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
  </v-card>
          </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="moreInfoModal"
      max-width="1300"
    >
      <v-card>
        <v-row class="ma-5">
          <v-col cols="11" class="text-h3">
            More Similar Operation Logs
          </v-col>
          <v-col cols="1" class="text-h3">
            <v-icon
              medium
              color="grey"
              class="ml-5"
              title="Close"
              @click="moreInfoModal = false"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-list
      subheader
      three-line
    >
      <div v-if="moreInfoLog">
        <v-simple-table dense v-if="moreInfoLog.length">
                <template v-slot:default>
                  <thead class= "v-data-table-header">
                    <tr>
                      <th class="text-left">
                        Operation
                      </th>
                      <th class="text-left">
                        Route
                      </th>
                      <th class="text-left">
                        Request Data
                      </th>
                      <th class="text-left">
                        HTTP Verb
                      </th>
                      <th class="text-left">
                        Service
                      </th>
                      <th class="text-left">
                        User Agent
                      </th>
                      <th class="text-left">
                        DateTime
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item,index) in moreInfoLog"
                      :key="index"
                    > <td>{{item.operation_name }}</td>
                      <td>{{item.request_endpoint}}</td>
                      <td>{{item.user_request_data }}</td>
                      <td>{{item.request_method }}</td>
                      <td>{{item.service_name }}</td>
                      <td>{{item.user_agent }}</td>
                      <td>{{formatDateTime(item.access_date) }}</td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
      </div>
    </v-list>
      </v-card>
    </v-dialog>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import moment from "moment";

export default {
  name: 'userLog',
  components: { Index, Buttons, Loading, Pagination },
  data: vm => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      menu2: false,
      moreInfoModal:false,
      perpage: 50,
      totRecords: null,
      page: 1,
      selectedItem:0,
      selectedSession: null,
      users:[],
      sessions: [],
      logs:[],
      isLoading: true,
      fullPage: true,
      sessionsData: [],
      username:'',
      max:moment(new Date()).format("YYYY-MM-DD"),
      min: moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD"),
      moreInfoLog:[]
    }),
    mounted() {
      //
  },

  methods: {
    showMoreInfoModal(module_id, operation_id, user_session_id){
      this.moreInfoLog = []
      this.moreInfoModal = true
      let check = this;
      const path = check.$url("LOG_MORE_INFO") +  "?module_id=" +
        module_id + "&operation_id=" +
        operation_id+ "&user_session_id=" +
        user_session_id
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
            if(res.data.payload.logs){
              check.moreInfoLog = res.data.payload.logs
            }

        })
        .catch((error) => {
          console.error(error);
        });

    },
    formatTime: function (d) {
      return moment(d).local().format("hh:mm:ss a").toUpperCase();
    },
    formatDateTime: function (d) {
      return moment(d).local().format("DD/MM/YYYY hh:mm:ss a").toUpperCase();
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getUsers(page = "") {
      let check = this;
      const path = check.$url("DB_USERS")
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
          if(res.data.payload.length){
            if(check.username === null){
              //check.user_id = res.data.payload[0][0]
            }
            check.users = []
            res.data.payload.forEach((u)=>{
              check.users.push({text:u[1], value:u[1]})
            })
          }

        })
        .catch((error) => {
          console.error(error);
        });
    },
    getlogs(page = "") {
      let check = this;
      check.selectedSession = null
      const path = check.$url("USER_LOG_DATA") +  "?user=" +
        check.username + "&page=" +
        check.page+ "&date=" +
        check.date;
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
          if(res.data.payload.error){
            // No logs found
            check.sessions = []
            check.logs = []
            check.selectedSession = null
          }else{
            check.sessionsData = res.data.payload.sessions;
            let sessionsData= res.data.payload.sessions
            console.log(sessionsData);
          if (Array.isArray(sessionsData) && sessionsData.length) {
              if(check.sessions.length){
                check.sessions = []
                check.logs = []
              }
              check.selectedSession = sessionsData[0].session_id
              let logs_temp = []
              sessionsData.forEach((s)=>{
                check.sessions.push({ text: check.formatTime(s.login_timestamp), icon: 'mdi-clock', session_id: s.session_id })
              })
              this.logs = logs_temp
          }
          }

        })
        .catch((error) => {
          console.error(error);
        });
    },
    submit() {
      this.$v.$touch();
    },
  },
  computed: {
    computedDateFormatted () {
        return this.formatDate(this.date)
      },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
  if(o){
    if(this.$route.params.name){
        this.username = this.$route.params.name
         this.getUsers();
      }else{
        let username = this.$utils.getCookies('username')
        if(username){
          this.username = username
        }
         this.getUsers();
      }
      this.getlogs();
  }
  },
  selectedSession (val){
    let selectedSession = val
    if(selectedSession){
        let sessionsData = this.sessionsData
        let logs_temp = []
              sessionsData.forEach((s)=>{
                if(selectedSession == s.session_id){
                  if(s.logs.length){
                  s.logs.forEach((l)=>{
                    let tempobj = {
                        id: l.id,
                        user_id: l.user_id,
                        module_id: l.module_id,
                        service_id: l.service_id,
                        operation_id: l.operation_id,
                        request_method: l.request_method,
                        request_endpoint: l.request_endpoint,
                        user_request_data: l.user_request_data,
                        user_agent: l.user_agent,
                        ip_address: l.ip_address,
                        access_date: l.access_date,
                        module_name: l.module_name,
                        service_name: l.service_name,
                        user_name: l.user_name,
                        operation_name: l.operation_name,
                        user_session_id: l.user_session_id,
                      };
                   logs_temp.push(tempobj)
                  })
                }
                }

              })
              this.logs = logs_temp
      }
  },
  date (val) {
    this.dateFormatted = this.formatDate(this.date)
    this.getlogs();
  },
  page: function (ob) {
    //this.getlogs(ob, this.query);
  },
 },
};
</script>
